import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BronzeChest from "../components/ChestModel";
import BronzeChestImg from "../assets/img/bronze_chest.png";
import SilverChestImg from "../assets/img/silver_chest.png";
import GoldChestImg from "../assets/img/gold_chest.png";
import { formatNumber } from "../utils/ExtraCodes";
import AlertModel from "../components/AlertModel";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export default function TreasureHunt() {
  // Change The Title
  document.title = "Treasure Hunt";

  const query = useQuery();
  const userUID = query.get("userUID");
  const [userData, setUserData] = useState({});
  const [userBeans, setUserBeans] = useState(0);
  const [showBronzeChest, setShowBronzeChest] = useState(false);
  const [chestModel, setChestModel] = useState(null);
  const [giftsData, setGiftsData] = useState(null);
  const [isAleart, setIsAleart] = useState(false);
  const [alertText, setAlertText] = useState("");

  useEffect(() => {
    fetch(
      `https://bunnylive.in/api/app/get/user/beans/with/uid?userUID=${userUID}`
    )
      .then((response) => response.json())
      .then((data) => setUserData(data.data))
      .catch((error) => console.error(error));
  }, [userUID]);

  useEffect(() => {
    fetch(
      `https://bunnylive.in/api/game/treasure-hunt/getWinnerGifts?gameId=10&userUID=${userUID}`
    )
      .then((response) => response.json())
      .then((data) => setGiftsData(data.data));
  }, [userData]);

  useEffect(() => {
    if (userData.userBeans) {
      setUserBeans(userData.userBeans);
    }
  }, [userData]);

  const handlePlayClick = (metal) => {
    setChestModel(metal);
    setShowBronzeChest(true);
  };

  const chestItems = [
    {
      name: "Bronze Chest",
      image: BronzeChestImg,
      beansAndDraw: 100,
    },
    {
      name: "Silver Chest",
      image: SilverChestImg,
      beansAndDraw: 1000,
    },
    {
      name: "Gold Chest",
      image: GoldChestImg,
      beansAndDraw: 10000,
    },
  ];

  return (
    <div className="bg-sky-200 p-4 sm:p-6 rounded-t-2xl w-full absolute bottom-0 mx-auto">
      <div className="bg-blue-500 rounded-t-xl p-3 sm:p-4 text-center relative">
        <h1 className="text-3xl sm:text-4xl font-bold text-yellow-300 drop-shadow-lg">
          Treasure Hunt!
        </h1>
        <button
          className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-6 h-6 sm:w-8 sm:h-8 flex items-center justify-center font-bold text-sm sm:text-base"
          onClick={() => {
            if (window.Android && window.Android.onCloseBtnClicked) {
              window.Android.onCloseBtnClicked();
            }
          }}
        >
          X
        </button>
        <button className="absolute top-2 left-2 bg-yellow-500 text-white rounded-full w-6 h-6 sm:w-8 sm:h-8 flex items-center justify-center font-bold text-sm sm:text-base">
          ?
        </button>
      </div>

      <div className="bg-blue-400 p-3 sm:p-4 space-y-3 sm:space-y-4">
        {chestItems.map((metal, index) => (
          <div
            key={index}
            className="bg-blue-300 rounded-lg p-2 sm:p-3 flex items-center justify-between"
          >
            <div>
              <h2 className="text-lg sm:text-xl font-semibold text-white">
                {metal.name} Chest
              </h2>
              <p className="text-xs sm:text-sm font-semibold text-gray-500">
                {Math.pow(10, index + 2)} Beans/Draw
              </p>
            </div>
            <div className="w-12 h-12 sm:w-16 sm:h-16 rounded-lg overflow-hidden">
              <img
                src={metal.image}
                alt={`${metal.name} Chest`}
                className="w-full h-full object-cover"
              />
            </div>
            <button
              onClick={() => handlePlayClick(metal)}
              className="bg-gradient-to-t from-yellow-300 to-yellow-500 text-yellow-700 border-2 border-yellow-300 shadow-lg shadow-yellow-600 px-3 py-1 sm:px-4 sm:py-2 rounded-full font-semibold text-sm sm:text-base"
            >
              Play
            </button>
          </div>
        ))}
      </div>

      <div className="bg-blue-500 rounded-b-xl p-3 sm:p-4 flex justify-between items-center">
        <div className="flex items-center">
          <img
            src="https://bunnylive.in/assets/images/icon_beans.png"
            alt="Bean"
            className="mr-1 sm:mr-2 w-4 h-4 sm:w-5 sm:h-5"
          />
          <span className="text-white text-sm sm:text-base">
            Balance:{" "}
            <span className="text-yellow-300">{formatNumber(userBeans)}</span>{" "}
            beans
          </span>
        </div>
        <div className="space-x-2">
          <button
            className="bg-green-500 text-white px-2 py-1 sm:px-3 sm:py-1 rounded-full text-sm sm:text-base"
            onClick={() => {
              if (window.Android && window.Android.onTopUpBtnClicked) {
                window.Android.onTopUpBtnClicked();
              }
            }}
          >
            Top-up
          </button>
          <button className="bg-green-500 text-white px-2 py-1 sm:px-3 sm:py-1 rounded-full text-sm sm:text-base">
            Rank
          </button>
        </div>
      </div>

      {showBronzeChest && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="relative">
            <BronzeChest
              userUID={userUID}
              model={chestModel}
              giftsData={giftsData}
              userBeans={userBeans}
              setUserBeans={setUserBeans}
              setAlertText={setAlertText}
              setAleart={setIsAleart}
            />
            <button
              onClick={() => setShowBronzeChest(false)}
              className="absolute top-2 right-2 bg-red-500 text-white rounded-full w-6 h-6 flex items-center justify-center font-bold"
            >
              X
            </button>
          </div>
        </div>
      )}
      {isAleart && <AlertModel text={alertText} setIsAleart={setIsAleart} />}
    </div>
  );
}
