import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import TreasureHunt from "./pages/TreasureHunt";
// import TeenPatti from "./pages/TeenPatti/TeenPatti";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<div>No data</div>} />
        {/* <Route path="/teen-patti" element={<TeenPatti />} /> */}
        <Route path="/treasure-hunt" element={<TreasureHunt />} />
      </Routes>
    </Router>
  );
}

export default App;
