import React, { useEffect, useState } from "react";
import WinnerModel from "./WinnnerModel";

const BronzeChest = ({
  userUID,
  model,
  giftsData,
  userBeans,
  setUserBeans,
  setAlertText,
  setAleart,
}) => {
  const [giftData, setGiftData] = useState([]);
  const [isWinner, setIsWinner] = useState(false);
  const [winnerModel, setWinnerModel] = useState([]);
  useEffect(() => {
    if (giftsData) {
      switch (model.beansAndDraw) {
        case 100:
          let gifts = giftsData.bronze;
          setGiftData(gifts);
          break;
        case 1000:
          let gifts1 = giftsData.silver;
          setGiftData(gifts1);
          break;
        case 10000:
          let gifts2 = giftsData.gold;
          setGiftData(gifts2);
          break;
      }
    }
  }, [giftsData]);

  const handlePlayClick = (item, combo) => {
    const { name, image, beansAndDraw } = item;
    const totalBet = beansAndDraw * combo;
    if (userBeans < totalBet) {
      setAlertText("Insufficient Beans");
      setAleart(true);
      return;
    }
    // Remove Beans
    setUserBeans(userBeans - totalBet);
    fetch(`https://bunnylive.in/api/game/treasure-hunt/potGame`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userUID: userUID,
        gameBet: beansAndDraw,
        combo: combo,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status) {
          if (data.data.winner) {
            // Handle Is User Won
            setWinnerModel(data.data.winner);
            setIsWinner(true);
          } else {
            // setAleart(true);
            // setAlertText("No Winner");
          }
        } else {
          setAleart(true);
          setAlertText(data.message);
        }
      });
    // Add Animation IN Button
    const btn = document.getElementById(`btn${combo}`);
    btn.classList.add("animate-ping");
    setTimeout(() => {
      btn.classList.remove("animate-ping");
    }, 500);
  };

  return (
    <div className="bg-sky-200 p-4 rounded-3xl w-full h-full max-w-md max-h-[90vh] overflow-y-auto">
      <div className="bg-sky-400 rounded-2xl p-4 flex flex-col h-full">
        <h2 className="text-white text-[15px] sm:text-2xl font-bold text-center bg-sky-500 rounded-full py-1 px-2 sm:px-6 mb-2">
          {model.name}
        </h2>

        <div className="flex flex-col items-center flex-grow">
          <img
            src={model.image}
            alt="Bronze Chest"
            className="w-20 h-20 sm:w-24 sm:h-24 mb-2 sm:mb-4"
          />
          <p className="bg-sky-300 text-sky-700 font-bold rounded-full px-3 sm:px-4 py-1 text-[10px] sm:text-base mb-2">
            {model.beansAndDraw} Beans/Draw
          </p>

          <div className="flex gap-2 sm:gap-4 w-full max-w-md mb-2 overflow-x-auto whitespace-nowrap text-center justify-center">
            {giftData.map((item, index) => (
              <div
                key={index}
                className="align-middle flex flex-col items-center"
              >
                <img
                  src={`https://bunnylive.in/${item.giftImage}`}
                  alt={item.giftName}
                  className="w-12 h-12 sm:w-12 sm:h-12 rounded-lg mb-1 sm:mb-2 bg-sky-300 p-0 object-cover border-2 border-sky-700"
                />

                <p className="text-sky-700 text-[12px] sm:text-sm text-center font-medium text-wrap w-12 mb-1">
                  {item.giftName}
                </p>
              </div>
            ))}
          </div>
        </div>

        <div className="flex justify-between mt-auto gap-1">
          <button
            id="btn1"
            className="bg-gradient-to-t from-sky-300 to-sky-500 border-2 border-sky-300 text-sky-700 font-bold rounded-full px-4 sm:px-6 py-1 sm:py-2 text-sm sm:text-base w-20 sm:w-24 "
            onClick={() => handlePlayClick(model, 1)}
          >
            1x
          </button>
          <button
            id="btn10"
            className="bg-gradient-to-t from-green-400 to-green-600 border-2 border-green-400 text-white font-bold rounded-full px-4 sm:px-6 py-1 sm:py-2 text-sm sm:text-base w-20 sm:w-24"
            onClick={() => handlePlayClick(model, 10)}
          >
            10x
          </button>
          <button
            id="btn100"
            className="bg-gradient-to-t from-yellow-400 to-yellow-600 border-2 border-yellow-400 text-white font-bold rounded-full px-4 sm:px-6 py-1 sm:py-2 text-sm sm:text-base w-20 sm:w-24"
            onClick={() => handlePlayClick(model, 100)}
          >
            100x
          </button>
        </div>
      </div>
      {isWinner && (
        <WinnerModel winnings={winnerModel} setIsAleart={setIsWinner} />
      )}
    </div>
  );
};

export default BronzeChest;
