import axios from "axios";

export const formatNumber = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  }
  return num;
};

export const numberFormat = (num) => {
  switch (num) {
    case 100:
      return num;
    case "1K":
      return 1000;
    case "10K":
      return 10000;
    case "100K":
      return 100000;
  }
};

export const callApi = async (url, method, uid, uToken) => {
  var config = {
    method: method,
    maxBodyLength: Infinity,
    url: url,
    params: {
      userUID: uid,
    },
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${uToken}`,
    },
  };
  const result = await axios.request(config);
  return result.data;
};
