import React, { useEffect, useState } from "react";

export default function WinnerModel({
  winnings,
  duration = 2000,
  setIsAleart,
}) {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    // Set a timeout to hide the alert after the specified duration
    const timer = setTimeout(() => {
      setVisible(false);
      setIsAleart(false);
    }, duration);

    // Cleanup the timer if the component is unmounted
    return () => clearTimeout(timer);
  }, [duration]);

  // If the alert is not visible, return null (nothing is rendered)
  if (!visible) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-200 text-white p-4 rounded-lg shadow-lg w-full max-w-md m-10">
        <h1 className="text-xl font-bold mb-4 text-center text-black">
          Winnings
        </h1>
        {winnings.map((item, index) => (
          <div key={index} className="flex items-center justify-between mb-2">
            <img
              src={`https://bunnylive.in/${item.giftImage}`}
              alt={item.giftName}
              className="w-12 h-12 sm:w-12 sm:h-12 rounded-lg mb-1 sm:mb-2 bg-sky-300 p-0 object-cover sm:p-2 border-2 border-sky-700"
            />

            <p className="text-sky-700 text-[12px] sm:text-sm text-center font-medium text-nowrap mb-1">
              Won {item.giftName}
            </p>

            <p className="text-sky-700 text-[12px] sm:text-sm text-center font-medium text-wrap w-12 mb-1">
              {item.giftPrice} Beans
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
